.datePicker {
  width: 100%;
}

.monthCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-month {
  height: 300px;
  overflow-y: scroll;
}

.flex-custom {
  display: flex;
  justify-content: space-between;
}
